<template>
<h1>About</h1>
  <div v-if="currentUser">   
    <div>
        <p>token : {{currentUser[0].token}} </p>
  </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
      
    }
  },
  mounted() {
    // if (!this.currentUser) {
    //   this.$router.push('/customerLogin');
    // }    
  }
};
</script>
